import { effect, inject, Injectable, signal } from '@angular/core';
import { IonicEventService } from '@pixels/client/ionic/ionic-event.service';
import { debounceTime, Subject, tap } from 'rxjs';

const MAX_TIMEOUT = 5000;

@Injectable({ providedIn: 'root' })
export class LoadingContainerService {
  public readonly isLoading = signal<boolean>(false);
  public readonly timeoutLoadingSource = new Subject<void>();
  private readonly ionicEventService = inject(IonicEventService);

  constructor() {
    this.timeoutLoadingSource.pipe(
      debounceTime(MAX_TIMEOUT),
      tap(() => this.isLoading.set(false))
    ).subscribe();
    effect(() => {
      if (this.isLoading()) {
        this.ionicEventService.open();
      } else {
        this.ionicEventService.close();
      }
    });
  }

  public startLoading(): void {
    // console.log('startLoading');
    this.isLoading.set(true);
    this.timeoutLoadingSource.next();
  }

  public stopLoading(): void {
    // console.log('stopLoading')
    this.isLoading.set(false);
  }
}
